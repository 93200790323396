import React from 'react';




var quieres = [];
var tienes = [];



export default class Calculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tama: 15,
            indices: [],
            necesitas: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],



        };
    }


    componentDidMount = () => {
        let a = [];
        for (let i = 0; i < this.state.tama; i++) {
            a[i] = i;
            quieres[i] = 0;
            tienes[i] = 0;
        }
        this.setState({
            indices: a,
        });
    }

    onCambioQuieres = (a, b) => {

        quieres[a] = b;
        this.onCalculo();
    }

    onCambioTienes = (a, b) => {

        tienes[a] = b;
        this.onCalculo();
    }

    onCalculo = () => {

        let d = [];
        d[0] = quieres[0] - tienes[0];
        for (let i = 1; i < this.state.tama; i++) {
            d[i] = Math.ceil(d[i - 1] * 2.5) + quieres[i] - tienes[i];
        }

        this.setState({ necesitas: d });


    }


    changeNivel = (event) => {

        this.setState({
            tama: event.target.value
        });
    this.componentDidMount();
    }


    render() {
        return (
            <div>
                <p>
                    Bienvenida al Dragon Merge Calculator:
                </p>
        

                {this.state.indices.map(i =>
                    <li key={i} style={{ listStyle: 'none' }}>
                        <div>
                            <span>Nivel {this.state.tama - i} </span>
                            <Cuadro nombre={i} onCambio={this.onCambioQuieres} defecto={0} />
                            <span> </span>
                            <Cuadro nombre={i} onCambio={this.onCambioTienes} defecto={0} />
                            <span> </span>
                            {this.state.necesitas[i]}
                        </div>
                    </li>)}
            </div>
        )
    }
};



class Cuadro extends React.Component {   //Muestra un cuadro de input para introducir el número de dados
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defecto
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }



    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            value: target.value
        });
        this.props.onCambio(name, value);
    }


    render() {
        return (
            <label>

                <input
                    name={this.props.nombre}
                    type="number"
                    value={this.state.value}
                    onChange={this.handleInputChange} />
            </label>
        );
    }
}
