
import './App.css';
import Calculator from "./Calculator.js"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://images-na.ssl-images-amazon.com/images/I/71ZHVaVXs5L.png" alt="logo" width="300px"/>
     
        <Calculator/>
      </header>
    </div>
  );
}

export default App;
